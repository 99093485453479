var app = {};

app.init = function () {
  app.hamburger();
  app.dropdown();
  app.accessibleNavDropdown();
};




//Mobile Hamburger Menu
app.hamburger = function () {
  $(".hamburger").on("click", function () {
    let isNavOpen = $(".body-with-open-nav");

    if (isNavOpen.length > 0) {
      $(".mobile-wrapper").attr("aria-hidden", "true");
    } else {
      $(".mobile-wrapper").attr("aria-hidden", "false");
    }
    $(".hamburger").toggleClass("is-active");
    $("body").toggleClass("body-with-open-nav");
  });

  // Tabbing through last menu item closes the modal
  //
  //ATTN: IF THE HEADER CONTENT HAS BEEN CHANGED FROM STARTER THEME DEFAULT, CORRECT HTML SELECTOR AND BROWSER WIDTH AT WHICH HEADER SWITCHES TO MOBILE NEEDS TO BE EDITED HERE
  //
  // direct child selector is included to account for sub menus
  $(".header-primary-nav > li:last-child a").focusout(function () {
    let width = $(window).width();
    if (width < 993) {
      $(".header-mobile-wrapper").attr("aria-expanded", "false");
      $("body").removeClass("body-with-open-nav");
      $(".hamburger").toggleClass("is-active");
      $(".hamburger").focus();
    }
  });

  // Pressing on the escape key closes the mobile menu
  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      if ($("body").hasClass("body-with-open-nav")) {
        $(".header-mobile-wrapper").attr("aria-expanded", "false");
        $("body").removeClass("body-with-open-nav");
        $(".hamburger").toggleClass("is-active");
        $(".hamburger").focus();
      }
    }
  });
};


app.dropdown = function () {
  //dropdown general functionality
  $(".dropdown").click(function () {
    if ($(this).hasClass("button-with-open-content")) {
      $(this).next(".dropdown-content").removeClass("dropdown-is-open");
      $(this).attr("aria-expanded", "false");
      $(this).removeClass("button-with-open-content");
    } else {
      $(this).next(".dropdown-content").addClass("dropdown-is-open");
      $(this).attr("aria-expanded", "true");
      $(this).addClass("button-with-open-content");
    }
  });
};


app.accessibleNavDropdown = function () {
  $(".menu-item-has-children>a")
    .attr("aria-haspopup", "true")
    .attr("aria-expanded", "false");

  $(".menu-item-has-children").on("mouseenter focusin", function () {
    $(".menu-item-has-children>a").attr("aria-expanded", "true");
  });

  $(".menu-item-has-children").on("mouseleave focusout", function () {
    $(".menu-item-has-children>a").attr("aria-expanded", "false");
  });
};

$(document).ready(function () {
  //call all the app functions
  app.init();

});